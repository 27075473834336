import React from "react";

const Pricing = ({ onClose }) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black/70 flex justify-center items-center z-[1001] overflow-y-auto">
      <div
        className="bg-[#161513] text-white p-10 rounded-[10px] text-center max-w-[600px] w-full box-border max-h-[90vh] overflow-y-auto font-montserrat
        md:max-w-[500px] md:p-8
        sm:max-w-[300px] sm:p-5"
      >
        <h2
          className="bg-gradient-to-r from-[#f59607] via-[#b515ffde] to-[#b515ffde] bg-clip-text text-transparent text-[34px] pb-[10px]
          md:text-[28px]
          sm:text-[22px]"
        >
          Pricing Information
        </h2>

        <p className="text-white pb-[10px]">
          At The Solitary Photographer, we offer a range of photography packages
          tailored to suit various needs. Below is a detailed breakdown of our
          pricing structure, including package details, additional costs, and
          payment terms. However, life is rarely straightforward and these
          prices are for guidance only. Each arrangement will be unique and can
          be tailored to your specific requirements. Please get in touch to
          discuss what it is you are looking to achieve and we will go from
          there.
        </p>

        <h2
          className="bg-gradient-to-r from-[#f59607] via-[#b515ffde] to-[#b515ffde] bg-clip-text text-transparent text-[30px] pb-[10px]
          md:text-[28px]
          sm:text-[22px]"
        >
          Typical Photography Packages
        </h2>

        <div className="pricing-info-container">
          <h3 className="pb-[10px]">1. Landscape Photography Package</h3>
          <p className="pb-[10px]">
            Price: £200 for a half-day session (up to 4 hours). Includes: Up to
            10 edited high-resolution images, online gallery for viewing and
            sharing, and a consultation to discuss your vision.
          </p>

          {/* ... other package details ... */}

          <h2 className="bg-gradient-to-r from-[#f59607] via-[#b515ffde] to-[#b515ffde] bg-clip-text text-transparent text-[30px] pb-[10px]">
            Additional Costs
          </h2>
          <p className="pb-[10px]">
            Travel Fees: A travel fee may apply for locations outside of
            Northern Cumbria. The fee is £0.45 per mile for distances beyond
            this range. This will be discussed and agreed prior to payment.
            Prints and Products: Physical prints and products can be ordered at
            an additional cost. Pricing varies based on size and type—please
            inquire for specific rates and options. Extra Editing: Additional
            editing requests beyond the package inclusions will incur a fee of
            £15 per image.
          </p>

          <h2 className="bg-gradient-to-r from-[#f59607] via-[#b515ffde] to-[#b515ffde] bg-clip-text text-transparent text-[30px] pb-[10px]">
            Payment Terms and Methods
          </h2>
          <p className="pb-[10px]">
            Deposit: A non-refundable deposit of 25% is required to secure your
            booking. The remaining balance is due one week before the scheduled
            date. Payment Methods: We accept payments via bank transfer, PayPal,
            and cash. Detailed payment instructions will be provided upon
            booking confirmation. Invoices: A formal invoice will be issued for
            all bookings, outlining the services provided and total costs. For a
            custom quote or to discuss your specific photography needs, please
            get in touch. We look forward to capturing your moments!
          </p>
        </div>

        <button
          onClick={onClose}
          className="mt-5 px-5 py-2.5 border-none rounded bg-gradient-to-r from-[#df8908] to-[#b515ffdd] text-white cursor-pointer
            hover:bg-[#df8908]
            md:text-base md:px-4 md:py-2
            sm:text-sm sm:px-3 sm:py-1.5"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default Pricing;
