import React from "react";
import mail_icon from "../../assets/mail_icon.svg";
import location_icon from "../../assets/location_icon.svg";
import call_icon from "../../assets/call_icon.svg";
import { GrInstagram } from "react-icons/gr";

const Contact = () => {
  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append("access_key", "760aa5f6-e3d8-427c-adbf-e00ec305da85");
    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: json,
    }).then((res) => res.json());

    if (res.success) {
      alert(res.messages);
    }
  };

  return (
    <div
      id="contact"
      className="flex flex-col items-center justify-center gap-8 mx-3 my-10
        sm:gap-12 md:gap-16 lg:gap-20 
        sm:mx-[40px] md:mx-[70px] lg:mx-auto
        sm:my-12 md:my-20
        max-w-[1200px] w-full"
    >
      <div className="relative w-full text-center">
        <h1
          className="text-[36px] font-semibold font-montserrat
          sm:text-[50px] md:text-[80px]"
        >
          Get in touch
        </h1>
      </div>

      <div
        className="flex justify-between gap-6 w-full max-w-[1000px]
        sm:gap-8 md:gap-[100px]
        md:flex-row flex-col px-2 sm:px-1"
      >
        {/* Left Section */}
        <div className="flex-1 flex flex-col gap-6 sm:gap-8 md:gap-[50px]">
          <h1
            className="text-[26px] sm:text-[36px] md:text-[50px] font-semibold font-montserrat 
            bg-gradient-to-r from-[#df8908] to-[#b515ff] bg-clip-text text-transparent"
          >
            Let's talk
          </h1>
          <p
            className="text-[16px] sm:text-[18px] md:text-[22px] leading-7 sm:leading-8 md:leading-9 
            text-[#d4d4d4] font-medium font-montserrat"
          >
            I'd love to hear from you! Whether you have questions about my work,
            want to discuss a potential project, or simply wish to connect,
            please feel free to reach out. Your inquiries and ideas are always
            welcome, and I'm here to help bring your vision to life. Let's Talk!
          </p>

          <div className="flex flex-col gap-5 sm:gap-6 md:gap-[30px]">
            <div className="flex items-center gap-3 sm:gap-[20px]">
              <img src={mail_icon} alt="" className="w-5 sm:w-6 md:w-8" />
              <p className="text-[14px] sm:text-[16px] md:text-[20px] text-[#d4d4d4] break-all sm:break-normal">
                info@the-solitary-photographer.co.uk
              </p>
            </div>
            <div className="flex items-center gap-[20px]">
              <img src={location_icon} alt="" className="w-6 sm:w-7 md:w-8" />
              <p className="text-[16px] sm:text-[18px] md:text-[20px] text-[#d4d4d4]">
                Cumbria
              </p>
            </div>
            <div className="flex items-center gap-[20px]">
              <img src={call_icon} alt="" className="w-6 sm:w-7 md:w-8" />
              <p className="text-[16px] sm:text-[18px] md:text-[20px] text-[#d4d4d4]">
                07766333040
              </p>
            </div>
            <div className="flex items-center gap-[20px]">
              <a
                href="https://www.instagram.com/the.solitary.photographer/"
                target="_blank"
                rel="noreferrer"
                className="text-[28px] text-white hover:text-[#df8908] transition-colors duration-300"
              >
                <GrInstagram />
              </a>
              <p className="text-[20px] text-[#d4d4d4]">
                ... Or find me on Instagram!
              </p>
            </div>
          </div>
        </div>

        {/* Right Section - Form */}
        <form
          onSubmit={onSubmit}
          className="flex-1 flex flex-col gap-5 sm:gap-6 md:gap-[30px] 
            w-[94%] sm:w-full max-w-[450px] mx-auto md:mx-0"
        >
          <div className="flex flex-col gap-2 sm:gap-3 w-full">
            <label
              className="text-[16px] sm:text-[18px] md:text-[20px] font-medium 
              bg-gradient-to-r from-[#df8908] to-[#b515ff] bg-clip-text text-transparent"
            >
              Your Name
            </label>
            <input
              type="text"
              placeholder="Enter your name"
              name="name"
              className="bg-transparent border-2 border-white rounded-[10px] 
                p-2.5 sm:p-3 md:p-[15px] 
                text-[14px] sm:text-[16px] md:text-[18px] 
                outline-none text-[#d4d4d4] placeholder:text-[#d4d4d4]/50
                focus:border-[#df8908] transition-colors duration-300
                w-full"
            />
          </div>

          <div className="flex flex-col gap-2 sm:gap-3">
            <label
              className="text-[16px] sm:text-[18px] md:text-[20px] font-medium 
              bg-gradient-to-r from-[#df8908] to-[#b515ff] bg-clip-text text-transparent"
            >
              Your Email
            </label>
            <input
              type="email"
              placeholder="Enter your email"
              name="email"
              className="bg-transparent border-2 border-white rounded-[10px] 
                p-2.5 sm:p-3 md:p-[15px] 
                text-[14px] sm:text-[16px] md:text-[18px] 
                outline-none text-[#d4d4d4] placeholder:text-[#d4d4d4]/50
                focus:border-[#df8908] transition-colors duration-300
                w-full max-w-full"
            />
          </div>

          <div className="flex flex-col gap-2 sm:gap-3">
            <label
              className="text-[16px] sm:text-[18px] md:text-[20px] font-medium 
              bg-gradient-to-r from-[#df8908] to-[#b515ff] bg-clip-text text-transparent"
            >
              Write your message here
            </label>
            <textarea
              name="message"
              rows="6"
              placeholder="Enter your message"
              className="bg-transparent border-2 border-white rounded-[10px] 
                p-2.5 sm:p-3 md:p-[15px] 
                text-[14px] sm:text-[16px] md:text-[18px] 
                outline-none resize-none text-[#d4d4d4] placeholder:text-[#d4d4d4]/50
                focus:border-[#df8908] transition-colors duration-300
                w-full max-w-full"
            />
          </div>

          <button
            type="submit"
            className="px-4 py-2 sm:px-5 sm:py-2.5 md:px-[30px] md:py-4 
              rounded-[40px] border-2 border-white 
              bg-gradient-to-r from-[#df8908] to-[#b515ffdd] text-white 
              font-montserrat text-[14px] sm:text-[16px] md:text-[18px] 
              cursor-pointer transition duration-300 hover:scale-110 w-fit"
          >
            Submit now
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
