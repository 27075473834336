import React from "react";

const About = () => {
  return (
    <div
      id="about"
      className="flex flex-col items-center justify-center gap-8 mx-4 
        sm:gap-12 md:gap-20 md:mx-[70px] lg:mx-[170px]"
    >
      <div className="relative">
        <h1
          className="text-[32px] font-semibold font-montserrat
          bg-gradient-to-r from-[#da7c25] to-[#b515ffba] bg-clip-text text-transparent
          sm:text-[45px] md:text-[75px]"
        >
          About me
        </h1>
      </div>

      <div className="flex flex-col gap-10 w-full sm:gap-12 md:gap-20 md:flex-row">
        <div className="hidden md:flex md:flex-shrink-0">
          <img
            src="https://i.imgur.com/A2g5Jrg.jpeg"
            alt="Profile"
            className="w-[400px] aspect-square rounded-full object-cover"
          />
        </div>

        <div className="flex flex-col gap-4 sm:gap-6 md:gap-10 md:pt-[70px]">
          <p className="text-[14px] leading-relaxed sm:text-[16px] md:text-[22px]">
            Specialising in landscape, event, and action photography, I strive
            to capture the essence of each moment.
          </p>
          <p className="text-[14px] leading-relaxed sm:text-[16px] md:text-[22px]">
            Whether it's the tranquillity of nature or the energy of a live
            event. With a keen eye for detail and a passion for storytelling, my
            goal is to deliver compelling images that resonate with viewers.
          </p>
        </div>
      </div>

      <div
        className="flex flex-col items-center gap-6 w-full mb-8
        sm:gap-8 sm:mb-10 md:flex-row md:justify-around md:mb-20"
      >
        <div className="flex flex-col items-center hover:scale-110 transition duration-500">
          <h1
            className="text-[32px] font-bold
            bg-gradient-to-r from-[#da7c25] to-[#b515ffba] bg-clip-text text-transparent
            sm:text-[40px] md:text-[60px]"
          >
            30+
          </h1>
          <p className="text-[14px] font-medium mt-2 text-center sm:text-[16px] md:text-[22px]">
            YEARS OF EXPERIENCE
          </p>
        </div>

        <hr className="hidden md:block lines" />

        <div className="flex flex-col items-center hover:scale-110 transition duration-500">
          <h1
            className="text-[32px] font-bold
            bg-gradient-to-r from-[#da7c25] to-[#b515ffba] bg-clip-text text-transparent
            sm:text-[40px] md:text-[60px]"
          >
            100+
          </h1>
          <p className="text-[14px] font-medium mt-2 text-center sm:text-[16px] md:text-[22px]">
            GIGS
          </p>
        </div>

        <hr className="hidden md:block lines" />

        <div className="flex flex-col items-center hover:scale-110 transition duration-500">
          <h1
            className="text-[32px] font-bold
            bg-gradient-to-r from-[#da7c25] to-[#b515ffba] bg-clip-text text-transparent
            sm:text-[40px] md:text-[60px]"
          >
            100's
          </h1>
          <p className="text-[14px] font-medium mt-2 text-center sm:text-[16px] md:text-[22px]">
            OF SATISFIED CUSTOMERS
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
