import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

const Hero = () => {
  return (
    <div
      id="home"
      className="flex flex-col items-center gap-[35px] px-4 w-full max-w-full overflow-hidden"
    >
      <img
        src="https://i.imgur.com/oqqZG00.jpeg"
        alt=""
        className="mt-[60px] w-[200px] aspect-square rounded-full object-cover border-4 border-white
        sm:w-[250px] md:w-[350px] md:mt-[100px]"
      />

      <h1
        className="pt-[40px] text-center w-full text-[28px] font-semibold leading-tight flex flex-col items-center gap-2
        sm:text-[35px] md:text-[75px] md:pt-[70px] md:w-[70%]"
      >
        <span className="gradient-text">I'm James,</span>
        <span className="text-[20px] sm:text-[28px] md:text-[55px]">
          The Solitary Photographer based in Cumbria
        </span>
      </h1>

      <p
        className="w-[90%] text-center text-[14px] leading-[28px]
        sm:text-[16px] sm:w-[80%] md:text-[22px] md:w-[50%] md:leading-[40px]"
      >
        I'm James Critchley, a freelance photographer living in Cumbria, where
        the breath-taking scenery inspires much of my work.
      </p>

      <div
        className="flex flex-col w-full gap-4 items-center mb-[30px] text-[16px]
        sm:text-[18px] md:flex-row md:justify-center md:gap-[25px] md:text-[24px] md:mb-[50px]"
      >
        <div className="w-[70%] md:w-auto">
          <div
            className="w-full px-[15px] py-[10px] rounded-[50px] bg-gradient-to-r from-[#df8908] to-[#b515ffdd] 
            cursor-pointer hover:border-2 hover:border-white text-center
            sm:px-[20px] sm:py-[12px] md:px-[45px] md:py-[25px]"
          >
            <AnchorLink
              className="no-underline text-white"
              offset={50}
              href="#contact"
            >
              Connect with me
            </AnchorLink>
          </div>
        </div>

        <div className="w-[70%] md:w-auto">
          <div
            className="w-full px-[15px] py-[10px] rounded-[50px] border-2 border-white 
            cursor-pointer hover:border-[#b415ff] text-center
            sm:px-[20px] sm:py-[12px] md:px-[75px] md:py-[25px]"
          >
            <AnchorLink
              className="no-underline text-white"
              offset={50}
              href="#gallery"
            >
              Gallery
            </AnchorLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
