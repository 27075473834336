const Services_Data = [
  {
    s_no: "01",
    s_name: "Event Photography",
    s_desc:
      "Capturing the special moments of your events with a keen eye for detail and emotion.",
    target: "#event-photo",
  },
  {
    s_no: "02",
    s_name: "Landscape Photography",
    s_desc:
      "Showcasing the beauty of nature through carefully composed landscape shots.",
    target: "#landscape-photo",
  },
  {
    s_no: "03",
    s_name: "Action Photography",
    s_desc:
      "Freezing dynamic moments in time with high-speed photography techniques.",
    target: "#action-photo",
  },
];

export default Services_Data;
