import React, { useState } from "react";
import mywork_data from "../../assets/mywork_data";
import arrow_icon from "../../assets/arrow_icon.svg";
import AnchorLink from "react-anchor-link-smooth-scroll";

const WorkGallery = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="w-full max-w-[900px] rounded-[10px] overflow-hidden bg-[#161513] my-4 sm:my-6">
      {/* Gallery Container */}
      <div className="rounded-[10px]">
        {/* Main Image Container */}
        <div className="relative aspect-[16/9] max-h-[600px] rounded-[10px]">
          {/* Main Image */}
          <div className="w-full h-full flex items-center justify-center rounded-[10px]">
            <img
              src={images[currentIndex].w_img}
              alt=""
              className="w-full h-full object-contain transition-all duration-300 rounded-[10px]"
            />
          </div>

          {/* Navigation Controls */}
          <div className="absolute inset-0 flex items-center justify-between p-2 sm:p-4">
            <button
              className="w-8 h-8 sm:w-10 sm:h-10 md:w-12 md:h-12 rounded-full bg-black/50 text-white backdrop-blur-sm 
                       flex items-center justify-center transition duration-300
                       hover:scale-110 hover:bg-gradient-to-r hover:from-[#df8908] hover:to-[#b515ff]
                       focus:outline-none focus:bg-black/50"
              onClick={handlePrev}
            >
              ←
            </button>

            <button
              className="w-8 h-8 sm:w-10 sm:h-10 md:w-12 md:h-12 rounded-full bg-black/50 text-white backdrop-blur-sm 
                       flex items-center justify-center transition duration-300
                       hover:scale-110 hover:bg-gradient-to-r hover:from-[#df8908] hover:to-[#b515ff]
                       focus:outline-none focus:bg-black/50"
              onClick={handleNext}
            >
              →
            </button>
          </div>
        </div>

        {/* Thumbnail Navigation - Now positioned below */}
        <div className="mt-2 backdrop-blur-sm p-0.5 sm:p-1 bg-[#161513]/60 rounded-[10px]">
          <div
            className="flex gap-1 sm:gap-2 justify-center overflow-x-auto px-1 sm:px-2 py-0.5 max-w-full 
            [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]"
          >
            {images.map((image, index) => (
              <button
                key={index}
                onClick={() => setCurrentIndex(index)}
                className={`flex-shrink-0 transition duration-300 w-10 sm:w-14 md:w-16 
                  ${
                    index === currentIndex
                      ? "ring-1 ring-[#df8908] opacity-100"
                      : "opacity-40 hover:opacity-100"
                  }`}
              >
                <img
                  src={image.w_img}
                  alt={`Thumbnail ${index + 1}`}
                  className="h-6 sm:h-10 md:h-12 w-10 sm:w-14 md:w-16 object-cover rounded-[6px]"
                />
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const MyWork = () => {
  return (
    <div
      id="work"
      className="flex flex-col items-center justify-center gap-8 mx-2 my-10
        sm:gap-12 md:gap-16 lg:gap-20 
        sm:mx-[40px] md:mx-[70px] lg:mx-auto
        sm:my-12 md:my-20
        max-w-[1200px] w-full"
    >
      <div className="relative w-full text-center">
        <h1
          className="text-[36px] font-semibold font-montserrat
          sm:text-[50px] md:text-[80px]"
        >
          My Latest Work
        </h1>
      </div>

      <div className="flex flex-col gap-6 md:gap-10 max-w-[900px] text-center mx-auto px-3 sm:px-0 w-[92%] sm:w-full">
        <p
          className="text-[16px] leading-7 text-[#d4d4d4] font-medium font-montserrat
          sm:text-[18px] sm:leading-8
          md:text-[22px] md:leading-9"
        >
          Explore my latest photography projects, where each image hopefully
          tells a unique story. This collection features recent captures across
          various themes, including stunning landscapes, dynamic events, and
          thrilling action shots. Every photograph reflects my ongoing journey
          as a photographer, highlighting my passion for capturing moments in
          time. I hope you enjoy viewing these new works as much as I enjoyed
          creating them.
        </p>
      </div>

      <div className="flex flex-col gap-6 sm:gap-8 md:gap-10 w-[92%] sm:w-full items-center max-w-[900px] mx-auto">
        <WorkGallery images={mywork_data} />
      </div>

      {/* Show More button */}
      <div
        className="flex items-center gap-3 border-2 border-white rounded-[50px] 
        px-4 py-2 sm:px-5 sm:py-2.5 md:px-[30px] md:py-4
        text-[14px] sm:text-[16px] md:text-[18px] font-medium cursor-pointer
        transition duration-[0.6s] hover:gap-[20px]
        hover:scale-110"
      >
        <AnchorLink
          href="#gallery"
          offset={50}
          className="no-underline text-white"
        >
          Show More
        </AnchorLink>
        <img
          src={arrow_icon}
          alt=""
          className="w-4 h-3
          sm:w-5 sm:h-4
          md:w-6 md:h-4"
        />
      </div>
    </div>
  );
};

export default MyWork;
