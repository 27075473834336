import React from "react";
import Services_Data from "../../assets/services_data";
import arrow_icon from "../../assets/arrow_icon.svg";
import Pricing from "../Modal/Pricing";
import AnchorLink from "react-anchor-link-smooth-scroll";

const Services = () => {
  const [isPricingModalOpen, setIsPricingModalOpen] = React.useState(false);

  const openPricingModal = () => {
    setIsPricingModalOpen(true);
  };

  const closePricingModal = () => {
    setIsPricingModalOpen(false);
  };

  return (
    <div
      id="services"
      className="flex flex-col items-center justify-center gap-8 mx-4 my-10
        sm:gap-12 md:gap-20 md:mx-[70px] lg:mx-[170px] md:my-20"
    >
      <div className="relative">
        <h1 className="text-[40px] sm:text-[50px] md:text-[70px] font-semibold">
          My Services
        </h1>
      </div>

      <div className="flex flex-col gap-6 md:gap-10 max-w-[800px] text-center">
        <p
          className="text-[16px] sm:text-[18px] md:text-[20px] leading-7 md:leading-8 
          text-[#d4d4d4] font-medium font-montserrat"
        >
          Welcome to my services section, where you can explore the diverse
          photography solutions I provide. Whether you're looking for stunning
          landscape captures, engaging event photography, or energetic action
          shots, my goal is to provide high-quality images that resonate with
          you. Explore the various services available, and let's work together
          to create something special!
        </p>
      </div>

      <div
        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 
        sm:gap-6 md:gap-8 w-full"
      >
        {Services_Data.map((service, index) => {
          return (
            <div
              key={index}
              className="flex flex-col justify-center gap-3 p-[20px] sm:p-[30px] md:p-[40px] 
                rounded-[10px] border-2 border-white transition duration-400 
                cursor-pointer hover:scale-105"
            >
              <h3 className="text-[18px] sm:text-[20px] md:text-[24px] font-semibold">
                {service.s_no}
              </h3>
              <h2
                className="text-[24px] sm:text-[28px] md:text-[32px] font-extrabold 
                bg-gradient-to-r from-[#df8908] to-[#b415ff] bg-clip-text text-transparent"
              >
                {service.s_name}
              </h2>
              <p
                className="text-[16px] sm:text-[18px] md:text-[20px] leading-7 md:leading-8 
                max-w-[280px] text-[#d4d4d4]"
              >
                {service.s_desc}
              </p>
              <div className="services-readmore">
                <p>
                  <AnchorLink
                    href={service.target}
                    offset={50}
                    className="text-inherit no-underline leading-7 text-[16px] sm:text-[18px]"
                  >
                    See More
                  </AnchorLink>
                </p>
                <img src={arrow_icon} alt="" className="w-3 sm:w-4" />
              </div>
            </div>
          );
        })}
      </div>

      <div className="text-[16px] sm:text-[18px] font-montserrat text-center px-4">
        <p>
          For information about current pricing structures hit the pricing
          button below...
        </p>
      </div>

      <button
        onClick={openPricingModal}
        className="px-[20px] sm:px-[25px] md:px-[30px] py-3 md:py-4 rounded-[40px] 
          border-2 border-white bg-gradient-to-r from-[#df8908] to-[#b515ffdd] 
          text-white font-montserrat text-[12px] sm:text-[13px] md:text-[14px] 
          cursor-pointer transition duration-300 hover:scale-110"
      >
        See Pricing
      </button>

      {isPricingModalOpen && <Pricing onClose={closePricingModal} />}
    </div>
  );
};

export default Services;
