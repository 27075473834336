import React, { useState } from "react";
import actionPhotos_data from "../../assets/actionPhotos_data";
import landscapePhotos_data from "../../assets/landscapePhotos_data";
import eventPhotos_data from "../../assets/eventPhotos_data";

const GallerySection = ({ images, title, sectionId }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="w-full max-w-[900px] rounded-[10px] overflow-hidden bg-[#161513] my-4 sm:my-6">
      {/* Title */}
      <h3
        className="text-[24px] sm:text-[28px] md:text-[32px] font-montserrat text-center py-4 
        bg-gradient-to-r from-[#df8908] to-[#b515ff] bg-clip-text text-transparent"
      >
        {title}
      </h3>

      {/* Gallery Container */}
      <div className="rounded-[10px]">
        {/* Main Image Container */}
        <div className="relative aspect-[16/9] max-h-[600px] rounded-[10px]">
          {/* Main Image */}
          <div className="w-full h-full flex items-center justify-center rounded-[10px]">
            <img
              src={images[currentIndex].w_img}
              alt=""
              className="w-full h-full object-contain transition-all duration-300 rounded-[10px]"
            />
          </div>

          {/* Navigation Controls */}
          <div className="absolute inset-0 flex items-center justify-between p-2 sm:p-4">
            <button
              className="w-8 h-8 sm:w-10 sm:h-10 md:w-12 md:h-12 rounded-full bg-black/50 text-white backdrop-blur-sm 
                       flex items-center justify-center transition duration-300
                       hover:scale-110 hover:bg-gradient-to-r hover:from-[#df8908] hover:to-[#b515ff]
                       focus:outline-none focus:bg-black/50"
              onClick={handlePrev}
            >
              ←
            </button>

            <button
              className="w-8 h-8 sm:w-10 sm:h-10 md:w-12 md:h-12 rounded-full bg-black/50 text-white backdrop-blur-sm 
                       flex items-center justify-center transition duration-300
                       hover:scale-110 hover:bg-gradient-to-r hover:from-[#df8908] hover:to-[#b515ff]
                       focus:outline-none focus:bg-black/50"
              onClick={handleNext}
            >
              →
            </button>
          </div>
        </div>

        {/* Thumbnail Navigation - Now positioned below */}
        <div className="mt-2 backdrop-blur-sm p-0.5 sm:p-1 bg-[#161513]/60 rounded-[10px]">
          <div
            className="flex gap-1 sm:gap-2 justify-center overflow-x-auto px-1 sm:px-2 py-0.5 max-w-full 
            [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]"
          >
            {images.map((image, index) => (
              <button
                key={index}
                onClick={() => setCurrentIndex(index)}
                className={`flex-shrink-0 transition duration-300 w-10 sm:w-14 md:w-16 
                  ${
                    index === currentIndex
                      ? "ring-1 ring-[#df8908] opacity-100"
                      : "opacity-40 hover:opacity-100"
                  }`}
              >
                <img
                  src={image.w_img}
                  alt={`Thumbnail ${index + 1}`}
                  className="h-6 sm:h-10 md:h-12 w-10 sm:w-14 md:w-16 object-cover rounded-[6px]"
                />
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const GalleryPhotos = () => {
  return (
    <div
      id="gallery"
      className="flex flex-col items-center justify-center gap-8 mx-2 my-10
        sm:gap-12 md:gap-16 lg:gap-20 
        sm:mx-[40px] md:mx-[70px] lg:mx-auto
        sm:my-12 md:my-20
        max-w-[1200px] w-full"
    >
      <div className="relative w-full text-center">
        <h1
          className="text-[36px] font-semibold font-montserrat
          sm:text-[50px] md:text-[80px]"
        >
          Gallery
        </h1>
      </div>

      <div className="flex flex-col gap-6 md:gap-10 max-w-[900px] text-center mx-auto px-3 sm:px-0 w-[92%] sm:w-full">
        <p
          className="text-[16px] leading-7 text-[#d4d4d4] font-medium font-montserrat
          sm:text-[18px] sm:leading-8
          md:text-[22px] md:leading-9"
        >
          Welcome to my gallery. Here you will find a collection of my
          photography that reflects my work across various themes, including
          landscapes, events, and action. Each image showcases my approach to
          capturing moments and the beauty of the world around us. I invite you
          to take a moment to explore and enjoy…
        </p>
      </div>

      <div className="flex flex-col gap-6 sm:gap-8 md:gap-10 w-[92%] sm:w-full items-center max-w-[900px] mx-auto">
        <div id="event-photo" className="w-full">
          <GallerySection
            title="Event Photos"
            images={eventPhotos_data}
            sectionId="eventGallery"
          />
        </div>

        <div id="landscape-photo" className="w-full">
          <GallerySection
            title="Landscape Photos"
            images={landscapePhotos_data}
            sectionId="landscapeGallery"
          />
        </div>

        <div id="action-photo" className="w-full">
          <GallerySection
            title="Action Photos"
            images={actionPhotos_data}
            sectionId="actionGallery"
          />
        </div>
      </div>
    </div>
  );
};

export default GalleryPhotos;
